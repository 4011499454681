
import {computed, defineComponent, onMounted, ref} from "vue";
import {LoadPanel, PinaFilterObjects, UserSelect} from "@/core/composite/composite";
import {useTaskStore} from "@/store/task.store";
import {setCurrentPageBreadcrumbsWithParams} from "@/core/helpers/breadcrumb";
import KtDatatable from "@/components/kt-datatable/KTDatatable.vue";
import BaseModal from "@/components/base/modal/BaseModal.vue";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";
import TaskService from "@/core/services/TaskService";
import Swal from "sweetalert2";
import TaskName from "@/views/task/TaskName.vue";
import SearchHorizontal from "@/components/common/SearchHorizontal.vue";
import TaskDueDate from "@/views/work/TaskDueDate.vue";
import UserLink from "@/views/user/UserLink.vue";
import TeamLink from "@/views/team/TeamLink.vue";

export default defineComponent({
  name: "TaskPools",
  components: {
    TeamLink,
    UserLink,
    TaskDueDate,
    SearchHorizontal,
    TaskName,
    DateTimeFormat,
    KtDatatable,
  },
  setup() {
    const state = ref({
      id: '',
      task: {name: '', inputs: [], results: [], description: '', assign: 'MY_SELF', saveAndStart: false}
    })
    const filterObjects = ref({teamTasks: true, status: 'NOT_SET', overdue: false, completed: false, noComplete: false})
    const taskStore = useTaskStore();
    const page = computed(() => taskStore.page)
    onMounted(() => {
      setCurrentPageBreadcrumbsWithParams("Tasks Pool",
        [
          {link: false, router: '', text: 'Tasks Pool'}
        ]
      )
    })
    const headers = ref<any>([])
    const buildHeaders = (isCompleted: boolean) => {
      if (isCompleted) {
        headers.value = [
          {
            name: 'Created',
            key: 'created',
            sortable: false
          },
          {
            name: 'Name',
            key: 'name',
            sortable: false
          },
          {
            name: 'Assigned',
            key: 'assigned',
            sortable: false
          },
          {
            name: 'Due Date',
            key: 'dueDate',
            sortable: false
          },
          {
            name: 'Completed',
            key: 'completedDt',
            sortable: false
          },
        ]
      } else {
        headers.value = [
          {
            name: 'Created',
            key: 'created',
            sortable: false
          },
          {
            name: 'Name',
            key: 'name',
            sortable: false
          },
          {
            name: 'Assigned',
            key: 'assigned',
            sortable: false
          },
          {
            name: 'Due Date',
            key: 'dueDate',
            sortable: false
          },
        ]
      }
    }
    buildHeaders(false);
    return {
      filterObjects,
      state,
      page,
      headers,
      buildHeaders,
      ...PinaFilterObjects(taskStore, filterObjects.value, ['owner', 'team'], 'sort:dueDate:asc'),
      ...LoadPanel(),
      ...UserSelect(),
    }
  },
  methods: {
    searchEvent() {
      if (this.filterObjects.completed) {
        this.filterObjects.noComplete = false
        this.buildHeaders(true)
        this.updateSort('sort:dueDate:desc')
      } else {
        this.buildHeaders(false)
        this.updateSort('sort:dueDate:asc')
      }
      this.updateFilterObject(this.filterObjects);
    },
    discardEvent() {
      this.buildHeaders(false)
      this.updateSort('sort:dueDate:asc')
      this.filterObjects.completed = false
      this.filterObjects.overdue = false
      this.filterObjects.noComplete = true
      this.updateFilterObject(this.filterObjects);
    },
    add() {
      const addTaskModal = this.$refs.addTaskModalRef as typeof BaseModal
      this.state.task = {name: '', inputs: [], results: [], description: '', assign: 'MY_SELF', saveAndStart: false}
      addTaskModal.showBaseModal();
    },
    edit(task: any) {
      this.state.task = {
        name: task.name,
        results: task.resutls,
        inputs: task.inputs,
        description: task.description,
        assign: '',
        saveAndStart: false
      }
      this.state.id = task.id
      const editTaskModal = this.$refs.editTaskModalRef as typeof BaseModal;
      editTaskModal.showBaseModal();
    },
    onCreated() {
      const addTaskModal = this.$refs.addTaskModalRef as typeof BaseModal
      addTaskModal.hideBaseModal();
      this.paginationDataLoad();
    },
    onEdited() {
      const editTaskModal = this.$refs.editTaskModalRef as typeof BaseModal;
      editTaskModal.hideBaseModal();
      this.paginationDataLoad();
    },
    onDeleteEntity(task) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        showLoaderOnConfirm: true,
        heightAuto: false,
        preConfirm: async () => {
          await TaskService.delete(task.id).then(() => {
            this.paginationDataLoad()
          })
        }
      })
    },
  }
})
